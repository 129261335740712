(function ($) {
  "use strict";
  $(function () {
    $(".js-tabs").on("click", function () {
      let id = $(this).attr("data-tabs-target");
      $(".tabs-title")
        .removeClass("is-active")
        .find(".tabs-link")
        .attr({ "aria-selected": false, tabindex: "-1" });
      $(`.tabs-link[data-tabs-target=${id}]`)
        .attr({ "aria-selected": true, tabindex: "0" })
        .closest(".tabs-title")
        .addClass("is-active");
      $(".tabs-panel").removeClass("is-active").attr("aria-hidden", "true");
      $(`.tabs-panel#${id}`).addClass("is-active").removeAttr("aria-hidden");
    });
  });
})(jQuery);
