(function ($) {
  "use strict";
  $(function () {
    $(".side__link").on("click", function (e) {
      e.preventDefault();
      let dataLink = $(this).data("link");

      if (dataLink) {
        let currSlide = $('[data-screen="' + dataLink + '"]');
        let positionCurrSlider = currSlide.offset().top;

        $("html, body").animate({ scrollTop: positionCurrSlider }, 600);
      }
    });
  });
})(jQuery);
