(function ($) {
  "use strict";
  $(function () {
    $(".button_archive").on("click", function () {
      let content = $(this).siblings(".callout__content");

      if (content.hasClass("callout__content_archive")) {
        content.slideDown().removeClass("callout__content_archive");
        return;
      }

      content.slideUp().addClass("callout__content_archive");
    });
  });
})(jQuery);
