(function ($) {
  "use strict";
  $(function () {
    $(document).click(function (e) {
      const $currItem = $(e.target).closest(".header__burger");
      if ($currItem.length !== 0) {
        $currItem.toggleClass("header__burger_opened");
        $(".js-mobile-hide").toggleClass("show-for-large");
        $(".header").toggleClass("header_opened");
      }
    });

    if (window.matchMedia("(max-width: 639px)").matches) {
      $(".header__item").on("click", function (e) {
        e.stopPropagation();
        $(this).find(".header__drop").eq(0).slideToggle();
        $(this).find(".header__icon").eq(0).toggleClass("header__icon_active");
      });

      $(".header__burger").on("click", function () {
        $("body").toggleClass("body-no-scroll");
      });

      $(".header__src").on("click", function () {
        if ($(".header__burger_opened").length > 0) {
          $(".header__burger").removeClass("header__burger_opened");
          $(".js-mobile-hide").addClass("show-for-large");
          $(".header").removeClass("header_opened");
        }
      });
    }
  });
})(jQuery);
