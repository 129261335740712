(function ($) {
  "use strict";
  $(function () {
    $(".side-menu__arrow").on("click", function () {
      $(this)
        .closest(".side-menu__item")
        .toggleClass("side-menu__item_open")
        .next("li")
        .find(".side-menu__hide-items")
        .slideToggle();
    });

    if ($(".side-menu__item_open").length) {
      $(".side-menu__item_open")
        .next("li")
        .find(".side-menu__hide-items")
        .slideToggle();
    }
  });
})(jQuery);
