(function ($) {
  "use strict";
  $(function () {
    if (window.matchMedia("(min-width: 640px)").matches) {
      $("body").on("click", function (e) {
        let targ = e.target;
        if (
          $(targ).closest(".header__src-block").length === 0 &&
          $(".header__src-form_active").length > 0
        ) {
          $(".header__src").show();
          $(".header__src-form").removeClass("header__src-form_active").hide();
          $(".button__text").show();
        }
      });

      $(".header__src").on("click", function () {
        $(".button__text").hide();
        $(".header__src-form").show().addClass("header__src-form_active");
        $(this).hide();
      });
    }

    if (window.matchMedia("(max-width: 639px)").matches) {
      $("body").on("click", function (e) {
        let targ = e.target;
        if (
          $(targ).closest(".header__src-block").length === 0 &&
          $(".header__src-form_active").length > 0
        ) {
          $(".header__src").show();
          $(".header__src-form").removeClass("header__src-form_active").hide();
          $(".header__close").removeClass("header__close_show");
        }
      });

      $(".header__src").on("click", function () {
        $(".header__close").addClass("header__close_show");
        $(".header__src-form").show().addClass("header__src-form_active");
        $(this).hide();
      });

      $(".header__close").on("click", function () {
        if ($(this).hasClass("header__close_show")) {
          $(".header__src").show();
          $(".header__src-form").removeClass("header__src-form_active").hide();
          $(".header__close").removeClass("header__close_show");
        }
      });
    }
  });
})(jQuery);
