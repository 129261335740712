(function ($) {
  "use strict";
  $(function () {
    function setHeightPlan() {
      for (let i = 1; i < 25; i++) {
        $(".js-equalize-" + i).css("height", "auto");
        let maxHeightPlan = Math.max.apply(
          null,
          $(".js-equalize-" + i)
            .map(function () {
              return $(this).height();
            })
            .get()
        );
        $(".js-equalize-" + i).height(maxHeightPlan);
      }
    }
    $(window).on("load", function () {
      setHeightPlan();
    });
    setHeightPlan();
    $(window).on("resize", function () {
      setHeightPlan();
    });

    window.setHeightPlan = setHeightPlan;
  });
})(jQuery);
