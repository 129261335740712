(function ($) {
  "use strict";
  $(function () {
    if ($(".structure__number").length) {
      const topPos = $(".structure__number").offset().top;
      const downPos = $(".structure__number").eq(4).offset().top;
      const height = $(".structure__number").outerHeight();
      const down = downPos + height;
      $(".structure-spacer__line").css("height", down - topPos);
    }
  });
})(jQuery);
