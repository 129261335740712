(function ($) {
  "use strict";
  $(function () {
    if (window.matchMedia("(max-width: 639px)").matches) {
      $(".button_show").on("click", function () {
        let content = $(this).prev();

        if (content.hasClass("callout__content_close")) {
          content.slideDown().removeClass("callout__content_close");
          $(this).text("Свернуть");
          return;
        }

        content.slideUp().addClass("callout__content_close");
        $(this).text("Показать");
      });
    }
  });
})(jQuery);
