(function ($) {
  "use strict";
  $(function () {
    $(window).scroll(function () {
      if ($(window).scrollTop() > 0) {
        $(".header").addClass("header_background");
        $(".header").find(".header__link").addClass("header__link_fixed");
      } else {
        if (!$("html").hasClass("is-reveal-open")) {
          $(".header").removeClass("header_background");
          $(".header").find(".header__link").removeClass("header__link_fixed");
        }
      }
    });
  });
})(jQuery);
