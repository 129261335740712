import "malihu-custom-scrollbar-plugin";
import "jquery-mousewheel";
(function ($) {
  "use strict";
  $(function () {
    $(".js-scroll").each(function () {
      $(this).mCustomScrollbar({
        scrollInertia: 600,
      });
    });
  });
})(jQuery);
