(function ($) {
  "use strict";
  $(function () {
    if (window.matchMedia("(min-width: 1030px)").matches) {
      let able = true;

      $(document).on("mousewheel", function (e) {
        let position = e.target.closest(".big");

        if (e.originalEvent.wheelDelta > 0 && able === true) {
          if (!$(position).hasClass("big_main")) {
            let prevSlide = $(position).prev(".big");

            if (prevSlide.length) {
              able = false;
              let prevPosition = prevSlide.offset().top;
              $("html, body").animate(
                { scrollTop: prevPosition },
                600,
                function () {
                  able = true;
                }
              );
            }
          }
        } else if (able === true) {
          let nextSlide = $(position).next(".big");

          if (nextSlide.length) {
            able = false;
            let nextPosition = nextSlide.offset().top;

            if ($(position).hasClass("big_main")) {
              nextPosition = nextSlide.offset().top - 30;
            }

            $("html, body").animate(
              { scrollTop: nextPosition },
              600,
              function () {
                able = true;
              }
            );
          }
        }
      });
    }
  });
})(jQuery);
