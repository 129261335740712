import "slick-carousel";
(function ($) {
  "use strict";
  $(function () {
    $(".carousel-main").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      fade: true,
      swipeToSlide: true,
      prevArrow:
        '<span><svg class="svg-inline--fa fa-angle-left fa-w-8 slick-prev fa-4x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path></svg></span>',
      nextArrow:
        '<span><svg class="svg-inline--fa fa-angle-right fa-w-8 slick-next fa-4x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg></span>',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    $(".news-slider").slick({
      infinite: false,
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      prevArrow:
        '<span class="slick-prev"><svg width="58" height="39" viewBox="0 0 58 39" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_b_108_2184)"><rect width="58" height="38.3019" fill="#071416" fill-opacity="0.58"/><rect x="0.5" y="0.5" width="57" height="37.3019" stroke="#FF8934"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M24.0405 13.6014L24.5011 14.0707L22.3277 16.2588L20.1542 18.447H29.7743H39.3945V19.1338V19.8207H29.7743H20.1542L22.3286 22.0098L24.5031 24.1989L24.0207 24.6844L23.5383 25.1698L20.523 22.1556L17.5077 19.1414L19.8969 16.728C21.211 15.4007 22.5772 14.0486 22.9329 13.7234L23.5798 13.1321L24.0405 13.6014Z" fill="#FF8934"/><defs><filter id="filter0_b_108_2184" x="-4" y="-4" width="66" height="46.3019" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImage" stdDeviation="2"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_108_2184"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_108_2184" result="shape"/></filter></defs></svg></span>',
      nextArrow:
        '<span class="slick-next"><svg width="58" height="39" viewBox="0 0 58 39" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_b_108_2187)"><rect width="58" height="38.3019" transform="matrix(-1 0 0 1 58 0)" fill="#071416" fill-opacity="0.58"/><rect x="-0.5" y="0.5" width="57" height="37.3019" transform="matrix(-1 0 0 1 57 0)" stroke="#FF8934"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M33.9595 13.6014L33.4989 14.0707L35.6723 16.2588L37.8458 18.447H28.2257H18.6055V19.1338V19.8207H28.2257H37.8458L35.6714 22.0098L33.4969 24.1989L33.9793 24.6844L34.4617 25.1698L37.477 22.1556L40.4923 19.1414L38.1031 16.728C36.789 15.4007 35.4228 14.0486 35.0671 13.7234L34.4202 13.1321L33.9595 13.6014Z" fill="#FF8934"/><defs><filter id="filter0_b_108_2187" x="-4" y="-4" width="66" height="46.3019" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImage" stdDeviation="2"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_108_2187"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_108_2187" result="shape"/></filter></defs></svg></span>',
    });

    $(".carousel-services").slick({
      infinite: true,
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    $(".carousel-news").slick({
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      swipeToSlide: true,
      autoplay: true,
      autoplaySpeed: 7000,
      autoplayHoverPause: true,
      responsive: [
        {
          breakpoint: 640,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    $(".car__anchor").on("click", function (e) {
      e.preventDefault();
      const $this = $(this);
      const index = $this.closest(".car").find(".car__anchor").index($this);
      $(".carousel-news").slick("slickGoTo", index);
    });

    $(".carousel-years").each(function (num, elem) {
      elem = $(elem);
      let arrows = elem.closest(".grid-container").find(".arrows");

      elem.slick({
        infinite: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        dots: false,
        adaptiveHeight: true,
        arrows: true,
        appendArrows: arrows,
        prevArrow:
          '<span class="slick-prev"><svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity="0.5" cx="22" cy="22" r="21.5" stroke="white"/><path d="M25 30L18 22.7586L25 15" stroke="white" stroke-width="2"/></svg></span>',
        nextArrow:
          '<span class="slick-next"><svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity="0.5" cx="22" cy="22" r="21.5" stroke="white"/><path d="M19 30L26.5 23L19 15" stroke="white" stroke-width="2"/></svg></span>',
      });
    });

    $(".carousel-banner").slick({
      infinite: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: false,
      prevArrow:
        '<span class="slick-prev"><svg width="47" height="33" viewBox="0 0 47 33" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_b_2_366)"><rect width="47" height="33" fill="#FF8934" fill-opacity="0.1"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M19.7782 12.0978C19.8632 12.1736 19.8921 12.2335 19.9068 12.3639L19.9258 12.5322L18.1609 14.2972C17.1902 15.2679 16.3959 16.0736 16.3959 16.0877C16.3959 16.1018 20.0725 16.1133 24.5662 16.1133H32.7364L32.8378 16.1854C32.8949 16.226 32.9564 16.3149 32.9786 16.3892C33.0103 16.4949 33.0076 16.5462 32.9648 16.6484C32.9355 16.7185 32.8674 16.8047 32.8134 16.84C32.7191 16.9017 32.3908 16.9046 24.5472 16.9134L16.3792 16.9225L18.1338 18.6751C19.8516 20.391 19.8889 20.431 19.9061 20.5842C19.9222 20.726 19.9123 20.753 19.8014 20.8704C19.693 20.9852 19.6595 21 19.5078 21H19.3366L17.1974 18.8646C15.6972 17.3671 15.0468 16.6949 15.0203 16.6146C14.9932 16.5328 14.9932 16.4672 15.0203 16.3854C15.0468 16.3051 15.6972 15.6329 17.1974 14.1354L19.3366 12H19.5026C19.6344 12 19.6912 12.0201 19.7782 12.0978Z" fill="#FF8934"/><defs><filter id="filter0_b_2_366" x="-17" y="-17" width="81" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImage" stdDeviation="8.5"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2_366"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2_366" result="shape"/></filter></defs></svg></span>',
      nextArrow:
        '<span class="slick-next"><svg width="47" height="33" viewBox="0 0 47 33" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g filter="url(#filter0_b_2_366)"><rect width="47" height="33" fill="#FF8934" fill-opacity="0.1"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M27.2218 12.0978C27.1368 12.1736 27.1079 12.2335 27.0932 12.3639L27.0742 12.5322L28.8391 14.2972C29.8098 15.2679 30.6041 16.0736 30.6041 16.0877C30.6041 16.1018 26.9275 16.1133 22.4338 16.1133H14.2636L14.1622 16.1854C14.1051 16.226 14.0436 16.3149 14.0214 16.3892C13.9897 16.4949 13.9924 16.5462 14.0352 16.6484C14.0645 16.7185 14.1326 16.8047 14.1866 16.84C14.2809 16.9017 14.6092 16.9046 22.4528 16.9134L30.6208 16.9225L28.8662 18.6751C27.1484 20.391 27.1111 20.431 27.0939 20.5842C27.0778 20.726 27.0877 20.753 27.1986 20.8704C27.307 20.9852 27.3405 21 27.4922 21H27.6634L29.8026 18.8646C31.3028 17.3671 31.9532 16.6949 31.9797 16.6146C32.0068 16.5328 32.0068 16.4672 31.9797 16.3854C31.9532 16.3051 31.3028 15.6329 29.8026 14.1354L27.6634 12H27.4974C27.3656 12 27.3088 12.0201 27.2218 12.0978Z" fill="#FF8934"/><defs><filter id="filter0_b_0_1" x="-17" y="-17" width="81" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImage" stdDeviation="8.5"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_0_1"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_0_1" result="shape"/></filter></defs></svg></span>',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    if (window.matchMedia("(max-width: 639px)").matches) {
      $(".carousel-messages").slick({
        infinite: false,
        dots: false,
        arrows: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        prevArrow:
          '<span class="slick-prev"><svg width="47" height="33" viewBox="0 0 47 33" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_b_110_3370)"><rect x="0.5" y="0.5" width="46" height="32" stroke="#E5E5E5"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M19.7782 12.0978C19.8632 12.1736 19.8921 12.2335 19.9068 12.3639L19.9258 12.5322L18.1609 14.2972C17.1902 15.2679 16.3959 16.0736 16.3959 16.0877C16.3959 16.1018 20.0725 16.1133 24.5662 16.1133H32.7364L32.8378 16.1854C32.8949 16.226 32.9564 16.3149 32.9786 16.3892C33.0103 16.4949 33.0076 16.5462 32.9648 16.6484C32.9355 16.7185 32.8674 16.8047 32.8134 16.84C32.7191 16.9017 32.3908 16.9046 24.5472 16.9134L16.3792 16.9225L18.1338 18.6751C19.8516 20.391 19.8889 20.431 19.9061 20.5842C19.9222 20.726 19.9123 20.753 19.8014 20.8704C19.693 20.9852 19.6595 21 19.5078 21H19.3366L17.1974 18.8646C15.6972 17.3671 15.0468 16.6949 15.0203 16.6146C14.9932 16.5328 14.9932 16.4672 15.0203 16.3854C15.0468 16.3051 15.6972 15.6329 17.1974 14.1354L19.3366 12H19.5026C19.6344 12 19.6912 12.0201 19.7782 12.0978Z" fill="#272727"/><defs><filter id="filter0_b_110_3370" x="-17" y="-17" width="81" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImageFix" stdDeviation="8.5"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_110_3370"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_110_3370" result="shape"/></filter></defs></svg></span>',
        nextArrow:
          '<span class="slick-next"><svg width="47" height="33" viewBox="0 0 47 33" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_b_110_3373)"><rect x="-0.5" y="0.5" width="46" height="32" transform="matrix(-1 0 0 1 46 0)" stroke="#E5E5E5"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M27.2218 12.0978C27.1368 12.1736 27.1079 12.2335 27.0932 12.3639L27.0742 12.5322L28.8391 14.2972C29.8098 15.2679 30.6041 16.0736 30.6041 16.0877C30.6041 16.1018 26.9275 16.1133 22.4338 16.1133H14.2636L14.1622 16.1854C14.1051 16.226 14.0436 16.3149 14.0214 16.3892C13.9897 16.4949 13.9924 16.5462 14.0352 16.6484C14.0645 16.7185 14.1326 16.8047 14.1866 16.84C14.2809 16.9017 14.6092 16.9046 22.4528 16.9134L30.6208 16.9225L28.8662 18.6751C27.1484 20.391 27.1111 20.431 27.0939 20.5842C27.0778 20.726 27.0877 20.753 27.1986 20.8704C27.307 20.9852 27.3405 21 27.4922 21H27.6634L29.8026 18.8646C31.3028 17.3671 31.9532 16.6949 31.9797 16.6146C32.0068 16.5328 32.0068 16.4672 31.9797 16.3854C31.9532 16.3051 31.3028 15.6329 29.8026 14.1354L27.6634 12H27.4974C27.3656 12 27.3088 12.0201 27.2218 12.0978Z" fill="#272727"/><defs><filter id="filter0_b_110_3373" x="-17" y="-17" width="81" height="67" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feGaussianBlur in="BackgroundImageFix" stdDeviation="8.5"/><feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_110_3373"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_110_3373" result="shape"/></filter></defs></svg></span>',
        adaptiveHeight: true,
      });
    }
  });
})(jQuery);
