import "@fancyapps/fancybox";
import "./foundation.js";
import "./fontAwesome.js";
import "./carousels.js";
import "./handleInputNumber.js";
import "./stickyFooter.js";
import "./replaceImgSvg.js";
import "./lazyLoadVideo.js";
import "./stickyHeader";
import "./toggleMenuOnMobile";
import "./screenScroll.js";
import "./sideMenu.js";
import "./spacer.js";
import "./tabsLinks.js";
import "./sideMenuItems.js";
import "./infoCostMessages.js";
import "./openSearch.js";
import "./customScrollbar.js";
import "./customEqualizer.js";
import "./showTextRates.js";
import "./showArchive.js";

import "../scss/style.scss";
(function ($) {
  "use strict";
  $(function () {
    // example
  });
})(jQuery);
